import PageBuilder, { getPageBuilderProps } from "@/mvcComponents/CommonUI/Sections/PageBuilder.controller";
import CommonSeoSchema from "@/SEO/Commons/CommonSeoSchema";
import GenericPageView from "./GenericPage.view";

const GenericPageController = ({ pageData = {}, customPageData = {} }) => {
  const { seo = {} } = pageData;

  const componentsArray = [
    {
      id: "GenericPageSEO",
      comp: CommonSeoSchema,
      props: {
        ...seo,
        noindex: seo?.noIndex,
        nofollow: seo?.noFollow,
        showOrganisation: false,
        showWebPageJson: false,
      },
    },
    {
      id: "PageBuilder",
      comp: PageBuilder,
      props: getPageBuilderProps(pageData),
    },
  ];

  return <GenericPageView components={componentsArray} />;
};

export default GenericPageController;
