import React, { useEffect } from "react";
import GenericPageService from "@/Logic/ServerLogic/GenericPage.service";

const GenericPage = ({
  slug,
  pageData,
  customPageData,
  alternateLanguageData,
}) => {
  const PageController = GenericPageService.getPageController(slug);
  const { languageAlternates = {}, canonicalUrl = "" } =
    alternateLanguageData || {};

  useEffect(() => {
    if (pageData.redirectUrl) {
      setTimeout(() => {
        window.location.href = pageData.redirectUrl;
      }, 700);
    }
  }, []);
  return (
    <PageController
      url={canonicalUrl}
      slug={slug}
      pageData={pageData}
      customPageData={customPageData}
      languageAlternates={languageAlternates}
    />
  );
};

export const getStaticPaths = async () => {
  return {
    paths: [],
    fallback: "blocking",
  };
};

export const getStaticProps = async ({ params, locale, defaultLocale }) => {
  try {
    const urlSlug = params.username;
    const PageDataController = await GenericPageService.getPageDataController(
      urlSlug
    );

    const data = await PageDataController.getPageData(
      locale,
      defaultLocale,
      urlSlug
    );
    let {
      pageData = {},
      customPageData = {},
      alternateLanguageData = {},
    } = data || {};

    return {
      props: {
        slug: urlSlug,
        pageData,
        customPageData,
        alternateLanguageData,
      },
    };
  } catch (err) {
    console.log("ERROR", err);
    return {
      notFound: true,
    };
  }
};

export default GenericPage;
