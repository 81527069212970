import BaseJobCanonicalsPageDataController from "./BaseJobCanonicalsPageData.controller";

class JobsInLocationCanonicalPageDataController extends BaseJobCanonicalsPageDataController {
  constructor() {
    super();
  }

  async getCustomPageData(url, pageData, tableData) {
    return await super.getCustomPageData(url, pageData, tableData);
  }

  async getTableData(locale, url, tableName, filter, query) {
    return await super.getTableData(locale, url, tableName, filter, query);
  }

  async getPageData(locale, defaultLocale, jobSlug) {
    return await super.getPageData(locale, defaultLocale, jobSlug);
  }
}

export default new JobsInLocationCanonicalPageDataController();