import BaseCanonicalPageDataController from "../../BaseCanonicalPageData.controller";

class CourseCanonicalsPageDataController extends BaseCanonicalPageDataController {
  constructor() {
    super();
  }

  #getCoursesListDataFromPageData(coursesPageData) {
    const sections = coursesPageData?.sections || [];
    const courseASection = sections.filter(
      (section) => section.__component === "section.course-a"
    );
    return courseASection.length > 0 ? courseASection[0]?.listData : [];
  }

  getCustomPageData(url, pageData) {
    try {
      const coursesList = this.#getCoursesListDataFromPageData(pageData);
      const paidCoursesIdAndLabels = coursesList?.map((course) => ({
        id: course?.slug,
        label: course?.title,
      }));
      return { coursesList, paidCoursesIdAndLabels };
    } catch (error) {
      console.error("Error returning custom page data:", error);
      return null;
    }
  }

  async getInterLinkingData(tableData) {
    let filter = `populate[interLinking][populate]=*`;
    return super.getInterLinkingData(tableData, "course-canonicals", filter);
  }

  async getTableData(locale, url, tableName, filter, query) {
    filter = `filters[url][$eq]=${url}`;
    tableName = "course-canonicals";
    return super.getTableData(locale, url, tableName, filter, query);
  }

  async getPageData(locale, defaultLocale, courseSlug) {
    try {
      let pageData = await super.getPageData(
        `/${courseSlug}`,
        locale,
        defaultLocale,
        "course-canonical"
      );
      return pageData;
    } catch (error) {
      console.error("Error returning page data:", error);
      throw error;
    }
  }
}

export default new CourseCanonicalsPageDataController();
