import { evaluateTernaryCondition } from "@/outscal-commons-frontend/utils/stringOperations";
import { capitalizeWords } from "../commonUtils";
import { HaloSectionLines } from "./jobMetaDataList";

const getHeading = (totalJobs, keywords, str, seoMetaDataTitle) => {
  const {
    location = "",
    jobRole = "",
    skill: techStack = "",
    locationType = "",
  } = keywords || {};

  let title =
    evaluateTernaryCondition(seoMetaDataTitle, {
      techStack,
      location,
      jobRole,
      locationType,
    }) ||
    `${totalJobs || ""} ${str
      .replace(/-/g, " ")
      .replace(/\b\w/g, (c) => c.toUpperCase())}` ||
    "";

  if (!location && !jobRole && !techStack && !locationType) {
    title = `${totalJobs || ""} Job Opportunities similar to ${str
      .replace(/-/g, " ")
      .replace(/\b\w/g, (c) => c.toUpperCase())}`;
  }
  return title
    .replace(/\{locationType\}/g, capitalizeWords(locationType) || "")
    .replace(/\{techStack\}/g, capitalizeWords(techStack) || "")
    .replace(/\{jobRole\}/g, capitalizeWords(jobRole) || "")
    .replace(/\{totalJobs\}/g, totalJobs || "")
    .replace(/ in \{location\}/g, location ? ` in ${location}` : "")
    .replace(
      /\{location\} has /g,
      location ? `${location} has ` : ""
    );
};

const getDescription = (totalJobs, keywords, str, seoMetaDataDescription) => {
  const {
    location = "",
    jobRole = "",
    skill: techStack = "",
    locationType = "",
  } = keywords || {};

  let description =
    evaluateTernaryCondition(seoMetaDataDescription, {
      techStack,
      location,
      jobRole,
      locationType,
    }) ||
    HaloSectionLines?.default ||
    "";

  if (!location && !jobRole && !techStack && !locationType) {
    description = totalJobs
      ? `Apply to ${totalJobs} Jobs similar to ${str.replace(
          /-/g,
          " "
        )} on Outscal.com | No. 1 Platform for Game development jobs, Courses and web3 careers, Gaming Courses, Mentorship and Placement Assistance.`
      : "";
  }

  return description
    .replace(/\{locationType\}/g, capitalizeWords(locationType) || "")
    .replace(/\{techStack\}/g, capitalizeWords(techStack) || "")
    .replace(/\{jobRole\}/g, capitalizeWords(jobRole) || "")
    .replace(/\{totalJobs\}/g, totalJobs || "")
    .replace(/ in \{location\}/g, location ? ` in ${location}` : "")
    .replace(
      /\{location\} has /g,
      location ? `${location} has ` : ""
    );
};

const getMetaDataFromKeywords = (
  totalJobs,
  keywords,
  str,
  seoMetaDataDetails
) => {
  let title = getHeading(
    totalJobs,
    keywords,
    str.replace(/-/g, " "),
    seoMetaDataDetails?.title
  );
  let description = getDescription(
    totalJobs,
    keywords,
    str.replace(/-/g, " "),
    seoMetaDataDetails?.description
  );

  return {
    meta: {
      title,
      description,
    },
    data: {
      heading: title,
      description,
    },
  };
};

export default getMetaDataFromKeywords;
