import Constant from "@/staticData/constant";
import getMetaDataFromKeywords from "@/utils/Jobs/getMetaDataFromKeywords";
import BaseCanonicalPageDataController from "../../BaseCanonicalPageData.controller";
import { GET_JOBS } from "@/Logic/GraphQL/Queries/Jobs.query";

class BaseJobCanonicalsPageDataController extends BaseCanonicalPageDataController {
  constructor() {
    super();
  }

  async #getJobsListDataFromFilters(filters) {
    let jobsData;
    const { data } = await this.#getJobs({
      filters,
      skip: 0,
      limit: Constant.PAGE_LIMIT,
    });
    jobsData = data?.jobs;
    
    if (jobsData?.dataList?.length === 0) {
      filters["skills"] = [];
      filters["jobCategoryTags"] = [];
      filters["locationType"] = [];
      
      const { data } = await this.#getJobs({
        filters,
        skip: 0,
        limit: Constant.PAGE_LIMIT,
      });
      jobsData = data?.jobs;
    }

    return { data: jobsData };
  }

  #getJobs({ filters = {}, skip = 0, limit = 0 }) {
    return this.apolloClient.query({
      query: GET_JOBS,
      variables: {
        filterObject: {
          filters,
          skip,
          limit,
        },
      },
    });
  }

  async getCustomPageData(url, pageData, tableData) {
    try {
      let jobSlug = url.split("/")[1];

      let jobs =
        (await this.#getJobsListDataFromFilters(tableData?.filter)) || {};

      let { seo = {} } = pageData || {};
      const { count = 0 } = jobs?.data || {};
      let metaData = getMetaDataFromKeywords(
        count,
        tableData?.replaceKeys,
        jobSlug,
        seo
      );

      return { filters: tableData?.filter, jobs: jobs?.data, metaData };
    } catch (error) {
      console.error("Error returning custom page data:", error);
      return null;
    }
  }

  async getInterLinkingData(tableData) {
    let filter = `populate[interLinking1][populate]=*&populate[interLinking2][populate]=*`;
    return super.getInterLinkingData(tableData, "job-canonicals", filter);
  }

  async getTableData(locale, url, tableName, filter, query) {
    filter = `filters[url][$eq]=${url}`;
    tableName = "job-canonicals";
    return super.getTableData(locale, url, tableName, filter, query);
  }

  async getPageData(locale, defaultLocale, jobSlug) {
    let pageData = await super.getPageData(
      `/${jobSlug}`,
      locale,
      defaultLocale,
      "job-canonical"
    );

    return pageData;
  }
}

export default BaseJobCanonicalsPageDataController;
