import BasePageDataController from "../../BasePageData.controller";

class GenericPageDataController extends BasePageDataController {
    constructor() {
        super();
    }

    async getPageData(locale, defaultLocale, slug) {
        try {
            let pageData = await super.getPageData(
                `/${slug}`,
                locale,
                defaultLocale,
            );
            return pageData;
        } catch (error) {
            console.error("Error returning page data:", error);
            throw error;
        }
    }
}

export default new GenericPageDataController();