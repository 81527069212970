import BasePageDataController from "./BasePageData.controller";

class BaseCanonicalPageDataController extends BasePageDataController {
  constructor() {
    super();
  }

  #replaceDynamicKeys(string = "", keysToReplace = {}) {
    try {
      Object.keys(keysToReplace).forEach((key) => {
        const dynamicKey = `{${key}}`;
        string = string.replace(
          new RegExp(dynamicKey, "g"),
          keysToReplace[key]
        );
      });
      return string;
    } catch (error) {
      console.error("Error replacing dynamic values:", error);
      return string;
    }
  }

  #replaceDynamicKeysInSections(sections = [], keysToReplace = {}) {
    try {
      return sections.map((section) => {
        if (section.hasDynamicFields) {
          let sectionString = JSON.stringify(section);
          sectionString = this.#replaceDynamicKeys(
            sectionString,
            keysToReplace
          );
          return JSON.parse(sectionString);
        }
        return section;
      });
    } catch (error) {
      console.error("Error replacing dynamic Keys in sections:", error);
      return sections;
    }
  }

  #replaceDynamicKeysInSeo(seo = {}, keysToReplace = {}) {
    try {
      let seoString = JSON.stringify(seo);
      seoString = this.#replaceDynamicKeys(seoString, keysToReplace);
      return JSON.parse(seoString);
    } catch (error) {
      console.error("Error replacing dynamic Keys in SEO:", error);
      return seo;
    }
  }

  #replaceDynamicKeysInFaqs(faqs = [], keysToReplace = {}) {
    try {
      return faqs.map((faq) => {
        let faqString = JSON.stringify(faq);
        faqString = this.#replaceDynamicKeys(faqString, keysToReplace);
        return JSON.parse(faqString);
      });
    } catch (error) {
      console.error("Error replacing dynamic Keys in FAQs:", error);
      return faqs;
    }
  }

  #populatePageDataDynamicKeys(keysToReplace = {}, pageData = {}) {
    try {
      let { seo = {}, faqs = {}, sections = [] } = pageData || {};

      seo = this.#replaceDynamicKeysInSeo(seo, keysToReplace);
      faqs = this.#replaceDynamicKeysInFaqs(faqs?.data || faqs, keysToReplace);
      sections = this.#replaceDynamicKeysInSections(sections, keysToReplace);

      let dynamicallyPopulatedPageData = {
        ...pageData,
        seo,
        faqs: { data: faqs },
        sections,
      };

      return dynamicallyPopulatedPageData;
    } catch (error) {
      console.error("Error returning custom page data:", error);
      return null;
    }
  }

  prepareInterLinkingData(data) {
    try {
      const attributes = data?.data?.attributes || {};
      const interLinkingData = {};

      Object.keys(attributes).forEach((key) => {
        const listData = attributes[key]?.data?.attributes?.listData || [];
        interLinkingData[key] = listData;
      });

      return interLinkingData;
    } catch (error) {
      console.error("Error fetching data for key Course Canonicals:", error);
      throw error;
    }
  }

  async getInterLinkingData(tableData, tableName, filter) {
    const url = `${process.env.STRAPI_BASE_URL}${tableName}/${tableData?.id}?${filter}`;
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${process.env.STRAPI_TOKEN}`,
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      const interLinking = this.prepareInterLinkingData(data);
      return interLinking;
    } catch (error) {
      console.error(`Error fetching data for key Course Canonicals:`, error);
      throw error;
    }
  }

  formatInterLinkingPageData(pageData, interLinkingData, key) {
    try {
      const sectionsDataWithInterLinkingList = pageData?.sections.map(
        (item) => {
          const interLinkingKey = Object.keys(interLinkingData).find(
            (interKey) => item.section_id === `${key}-${interKey}`
          );
          if (interLinkingKey) {
            return {
              ...item,
              listData: interLinkingData[interLinkingKey],
            };
          }
          return item;
        }
      );

      return {
        ...pageData,
        sections: sectionsDataWithInterLinkingList,
      };
    } catch (error) {
      console.error("Error formatting page data:", error);
      return null;
    }
  }

  async #getFallbackPageData(url, locale, defaultLocale = "in", key) {
    const pagePopulateQuery =
      "&populate[page][populate][sections][populate][showOn][populate]=*&populate[page][populate][sections][populate][listData][populate][image][fields][0]=url&populate[page][populate][sections][populate][listData][populate][skills][fields][0]=display_name&populate[page][populate][sections][populate]=*&populate[page][populate][sections][populate][bgImage][fields][0]=url&populate[page][populate][seo][populate]=*&populate[page][populate][faqs]=*";

    const tableData = await this.getTableData(
      locale,
      url,
      "",
      "",
      pagePopulateQuery
    );
    let pageData = tableData?.page || {}
    pageData =
      await this.specialSectionsLogicController.populateSpecialSectionsData(
        pageData
      );
    return { tableData, pageData }
  }

  async getPageData(url, locale, defaultLocale = "in", key) {
    try {
      let alternateLanguageData = this.makeAlternateLanguageData(
        url,
        locale,
        defaultLocale
      );
      const pagePopulateQuery =
        "&populate[sections][populate]=*&populate[showOn][populate]=*&populate[sections][populate][listData][populate][image][fields][0]=url&populate[sections][populate][listData][populate][skills][fields][0]=display_name&populate[sections][populate][bgImage][fields][0]=url";

      const directPageData = await super.getPageData(url, pagePopulateQuery, locale, defaultLocale);
      let pageData = directPageData?.pageData;
      let tableData = {};
      
      if (!pageData) {
        const fallbackPageData = await this.#getFallbackPageData(url, locale, defaultLocale, key);
        pageData = fallbackPageData?.pageData;
        tableData = fallbackPageData?.tableData;
      } else {
        tableData = await this.getTableData(
          locale,
          url,
          "",
          "",
        );
      }

      if (!pageData || pageData?.sections?.length === 0) {
        throw new Error("Page data is undefined or empty");
      }

      let interLinkingData = await this.getInterLinkingData(tableData);

      if (!tableData || Object.keys(tableData).length === 0) {
        throw new Error("Table data is undefined or empty");
      }

      let dynamicallyPopulatedPageData = this.#populatePageDataDynamicKeys(
        tableData?.replaceKeys,
        pageData
      );

      dynamicallyPopulatedPageData = this.formatCustomDataSections(
        dynamicallyPopulatedPageData
      );

      dynamicallyPopulatedPageData = this.formatInterLinkingPageData(
        dynamicallyPopulatedPageData,
        interLinkingData,
        key
      );

      dynamicallyPopulatedPageData = this.fixBreadcrumbItemListUrl(
        dynamicallyPopulatedPageData
      );

      let customPageData = await this.getCustomPageData(
        url,
        dynamicallyPopulatedPageData,
        tableData
      );

      this.setUpRelCanonical(alternateLanguageData, pageData);

      return {
        pageData: dynamicallyPopulatedPageData,
        customPageData,
        tableData,
        alternateLanguageData,
      };
    } catch (error) {
      console.error("Error fetching page data:", error);
      throw error;
    }
  }
}

export default BaseCanonicalPageDataController;
