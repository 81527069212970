import dynamic from "next/dynamic";
import SkillsJobsCanonicalsPageDataController from "./PageDataControllers/JobCanonicals/SkillsJobsPageData.controller";
import SkillsJobsInLocationCanonicalPageDataController from "./PageDataControllers/JobCanonicals/SkillJobsInLocationCanonicalPageData.controller";
import CourseCanonicalsPageDataController from "./PageDataControllers/CourseCanonicals/CourseCanonicalsPageData.controller";
import JobsInLocationCanonicalPageDataController from "./PageDataControllers/JobCanonicals/JobsInLocationCanonicalPageData.controller";
import GenericPageDataController from "./PageDataControllers/Generic/GenericPageData.controller";
import GenericPageController from "@/mvcComponents/Pages/GenericPage/GenericPage.controller";

const JobsCanonicalPageController = dynamic(() =>
  import("@/mvcComponents/Pages/JobsCanonicalPage/JobsCanonicalPage.controller")
);
const CoursesCanonicalPage = dynamic(() =>
  import(
    "@/mvcComponents/Pages/CoursesCanonicalPage/CoursesCanonicalPage.controller"
  )
);

const pageMap = {
  skillsJobsInLocation: {
    keywords: ["-jobs-in-"],
    pageDataController: SkillsJobsInLocationCanonicalPageDataController,
  },
  jobsInLocation: {
    keywords: ["jobs-in-"],
    pageDataController: JobsInLocationCanonicalPageDataController,
  },
  skillJobs: {
    keywords: ["jobs"],
    pageDataController: SkillsJobsCanonicalsPageDataController,
  },
  courses: {
    keywords: ["courses"],
    pageDataController: CourseCanonicalsPageDataController,
  },
};

const pageControllersMap = {
  jobsInLocation: JobsCanonicalPageController,
  skillsJobsInLocation: JobsCanonicalPageController,
  skillJobs: JobsCanonicalPageController,
  courses: CoursesCanonicalPage,
};

class GenericPageService {
  constructor(map, controllersMap) {
    this.pageMap = map;
    this.pageControllersMap = controllersMap;
  }

  #getPageKeyFromSlug(slug) {
    for (const pageKey in this.pageMap) {
      const { keywords } = this.pageMap[pageKey];
      for (const keyword of keywords) {
        const regex = new RegExp(`\\b${keyword}\\b`, "i");
        if (regex.test(slug)) {
          return pageKey;
        }
      }
    }
  }

  getPageDataController(slug) {
    const pageKey = this.#getPageKeyFromSlug(slug);
    const { pageDataController } = this.pageMap[pageKey] || {};
    const returnVal = pageDataController || GenericPageDataController;
    return returnVal;
  }

  getPageController(slug) {
    const pageKey = this.#getPageKeyFromSlug(slug);
    const pageController = this.pageControllersMap[pageKey] || GenericPageController;
    return pageController;
  }
}

export default new GenericPageService(pageMap, pageControllersMap);
