export const LocationTypeList = ["remote", "onsite", "hybrid"];
export const TechStackList = [
  "Unity",
  "C#",
  "C++",
  "Unreal",
  "Android Development",
];
export const JobRoleList = [
  {
    label: "Programming",
    related_skills: ["C++", "Unity", "Java", "Android", "C#"],
  },
  {
    label: "Game Production",
    related_skills: [
      "Helix",
      "Unity",
      "Java",
      "Game Production Management",
      "Unreal",
    ],
  },
  {
    label: "QA",
    related_skills: ["Scraping", "Selenium", "Automation", "Manual Testing"],
  },
  {
    label: "Tech Art",
    related_skills: [
      "Game Design",
      "Animation",
      "Blender",
      "3D Modelling",
      "GUI Animation",
    ],
  },
  {
    label: "Marketing",
    related_skills: [
      "Game Content",
      "Brand Marketing",
      "SEO",
      "Online Promotions",
      "Social Media Engagement",
    ],
  },
];
export const LocationList = [
  { label: "India" },
  { label: "USA" },
  { label: "Canada" },
  { label: "Germany" },
  { label: "Australia" },
];

export const JobModifiers = ["Top", "Popular", "Best", "Latest", "New", ""];
export const JobDescModifiers = [
  "s",
  " Opportunities",
  " Postings",
  " Listings",
];

export const HaloSectionLines = {
  default:
    "Browse through a list of curated jobs handpicked just for you. Use your {techStack}{jobRole} skills to crack interviews in top gaming companies offering over {totalJobs} {locationType} job opportunities in {location}. Apply for jobs on Outscal -  the No. 1 Platfrom in {location} for Careers, Courses and {techStack}{jobRole} mentorship.",
  skillOrRoleAndLocation:
    "Apply to {totalJobs} {techStack}{jobRole} in {location}. Take a look at the latest {techStack}{jobRole} job openings in {location} across the top companies.",
  location:
    "Apply to {totalJobs} job vacancies in {location}. Explore & find the latest jobs in {location}. Register for free on Outscal and get jobs in {location}.",
};

// skillOrRole, jobCount, locationType, location
